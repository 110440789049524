<template lang="pug">
Buttons.column-gap-4
  Button(
    type="primary"
    label="New Creative"
    :disabled="disbale_new_creative"
    :animate="true"
    data-intercom-target="new-creative-button"
    v-google-analytics="{ key: 'click', element_name: 'create creative start' }"
    v-user-guiding="'new-creative-button'"
    @click="createPlacement('infeed')"
  )
  SearchSelectCustom(
    variant="creative_type"
    :search="false"
    :handler="handleOptions"
    @select="selectType"
  )
    template(v-slot:placeholder="{opened}")
      Button(type="primary" :animate="true" :disabled="disbale_new_creative" :active="opened" :flip="true")
        template(v-slot:suffix)
          IconDropdown
</template>

<script>
import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import CreativesMixins from '@cm/Views/Creatives/Components/CreativesMixins.vue';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import IconDropdown from '@master/UI/Buttons/Icons/IconDropdown.vue';
import SearchSelectCustom from '@master/UI/SearchSelect/SearchSelectCustom.vue';

export default {
  name: 'CreativesCreatePlacement',

  mixins: [CreativeTraits, CreativesMixins],

  components: {
    Button,
    Buttons,
    IconDropdown,
    SearchSelectCustom,
  },

  props: {
    disbale_new_creative: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    type_options() {
      let options = [
        {
          value: 'infeed',
          label: 'Standard Infeed',
          description: 'It appears inside of content feeds.',
          icons: ['nexd-icon-16-mobile', 'nexd-icon-16-desktop'],
        },
        {
          value: 'interstitial',
          label: 'Interstitial',
          description: 'Fullscreen ads that overlay the content.',
          icons: ['nexd-icon-16-mobile'],
        },
        {
          value: 'interscroller',
          label: 'Interscroller',
          description: 'Scroll-in ad, where creative is fullscreen size, but visible though a viewport (aka understitial or underlay).',
          icons: ['nexd-icon-16-mobile'],
        },
        {
          value: 'expandable',
          label: 'Expandable',
          description: 'Two-state ad that transition from the first state to the second state.',
          icons: ['nexd-icon-16-mobile'],
        },
        {
          value: 'endcard',
          label: 'Dynamic endcard',
          description: 'Two-state ad that automatically transition from the first state to the second state.',
          icons: ['nexd-icon-16-mobile'],
        },
        {
          value: 'lightbox',
          label: 'Lightbox',
          description: 'Two-state ad that show the second state with a popup.',
          icons: ['nexd-icon-16-mobile', 'nexd-icon-16-desktop'],
        },
        {
          value: 'responsive',
          label: 'Responsive',
          description: 'Multiple sizes of the same creative.',
          icons: ['nexd-icon-16-mobile', 'nexd-icon-16-desktop'],
        }
      ];

      if (this.instream_expert) {
        options = [];
      }

      options.push(
        {
          value: 'vast',
          label: 'VAST Video',
          description: 'Can use our still layout.',
          icons: ['nexd-icon-16-video'],
        },
        {
          value: 'ctv_vast',
          label: 'CTV VAST Video',
          description: 'In-stream video ad type to run an ad suitable for UHD(4K) TV. Use still or video layout.',
          icons: ['nexd-icon-16-video'],
        },
        {
          value: 'vpaid',
          label: 'VPAID Video',
          description: 'Is available on all desktop layouts with video.',
          icons: ['nexd-icon-16-video'],
        },
      );

      return options;
    },
  },

  methods: {
    createPlacement(type) {
      if (!this.disbale_new_creative) {
        this.$emit('createPlacement', type);
      }
    },

    handleOptions() {
      return Promise.resolve(this.type_options);
    },

    selectType({ value, label }, close) {
      this.createPlacement(value);
      close();

      window?.ga?.e({
        element_name: label,
        interaction_type: 'click',
      });
    },
  },
};
</script>

<template lang="pug">
div.creative-card-details
  div.flex
    div.flex-grow

      //- show creative id if it's not new (only for admins)
      template(v-if="admin_toggle && !is_new")
        CreativeRow(label="ID:")
          template(v-slot:content)
            div.fs-14 {{ creative.creative_id }}
        CreativeRow(v-if="creative.expanded" label="ID (expanded):")
          template(v-slot:content)
            div.fs-14 {{ creative.expanded.creative_id }}

      //- Type selection row
      CreativeRow(
        :key="'type'"
        label="Type:"
        :tooltip="{'template': 'cm.creativeType', position: 'right'}"
      )
        template(v-slot:content)
          SearchSelect(
            ref="type_options"
            :options="type_options"
            :first="false"
            :search="false"
            :value="active_type"
            :disabled="isDevtoolCreative() || switching_type"
            width="100%"
            @input="typeChangeHandler"
          )

      //- Size selection row && Expandable|Dynamic Endcard first state size selection row
      CreativeRow(
        :key="'first-state-placement'"
        :label="size_row_label"
        :tooltip="!isDynamicEndcard() && !isExpandable() ? { template: this.isQuantum() ? 'cm.placementSizeResponsive' : 'cm.placementSize' } : null"
      )
        template(v-slot:switch-type)
          div(v-if="isDynamicEndcard() || isExpandable()")
            small.color-gray-800(v-if="isSomeSortOfFullscreen()")
              span or
              | &nbsp;
              Button(type="link" label="In-Feed" :text="true" @click="typeChangeHandler(isDynamicEndcard() ? 20 : 10)")
            small.color-gray-800(v-else)
              span or
              | &nbsp;
              Button(
                type="link"
                label="Fullscreen"
                :text="true"
                :disabled="expandable_first_state_disabled"
                :tooltip="expandable_first_state_tooltip"
                @click="typeChangeHandler(isDynamicEndcard() ? 21 : 11)"
              )

        template(v-slot:content)
          template(v-if="isSomeSortOfFullscreen()")
            div.fs-14(style="line-height: 2rem;") Fullscreen
          template(v-else-if="isQuantum()")
            div.fs-14(style="line-height: 2rem;") Multiple
          CreativeSize(
            v-else
            :creative="creative"
            :can_reimport_social="can_reimport_social"
            @save="sizeChangeHandler"
            @reimport="$emit('reimport')"
          )

      //- Expandable second state size selection row
      CreativeRow(
        v-if="(isExpandable() || isLightbox()) && creative.expanded != null"
        :key="'second-state-placement'"
        label="Second state size (px):"
      )
        template(v-if="isExpandable()" v-slot:switch-type)
          div
            small.color-gray-800
              span or
              | &nbsp;
              Button(
                type="link"
                :label="isSomeSortOfFullscreen(creative.expanded.type) ? 'In-Feed' : 'Fullscreen'"
                :text="true"
                :disabled="expandable_second_state_disabled"
                :tooltip="expandable_second_state_tooltip"
                @click="typeChangeHandler(isSomeSortOfFullscreen(creative.expanded.type) ? 0 : 1, creative.expanded)"
              )

        template(v-slot:content)
          template(v-if="isSomeSortOfFullscreen(creative.expanded.type)")
            div.fs-14(style="line-height: 2rem;") Fullscreen
          CreativeSize(
            v-else
            :creative="creative.expanded"
            :expanded="true"
            :can_reimport_social="can_reimport_social"
            @save="sizeChangeHandler(true)"
            @reimport="$emit('reimport')"
          )

    //- Type tooltip
    NotificationBox.my-0(
      v-if="type_tooltips[active_type] != null"
      :title="`${type_tooltip_title} creative type`"
      :info="type_tooltips[active_type].tooltip"
      :href="is_sdk_user ? null : type_tooltips[active_type].link"
      width="300px"
    )
</template>

<script>
import CreativeSizeService from '@master/Services/CreativeSizeService';
import AssetsLibraryService from '@master/Services/AssetsLibraryService';
import TemplatesService from '@master/Services/TemplatesService';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import CreativeMixins from '@cm/Views/Creatives/Creative/CreativeMixins.vue';
import CreativesMixins from '@cm/Views/Creatives/Components/CreativesMixins.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import Button from '@master/UI/Buttons/Button.vue';
import NotificationBox from '@master/UI/NotificationBox/NotificationBox.vue';
import CreativeSize from '@master/UI/CreativeSize/CreativeSize.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';

import CreativeRow from '@cm/Views/Creatives/Creative/CreativeRow.vue';

import { TEMPLATE, TYPE, SIZE } from '@master/constants';

const NOT_COMPATIBLE_LAYOUT_MESSAGE = "Your selected type is not compatible with the current layout and that's why we will reset the settings.";

export default {
  name: 'CreativeDetails',
  mixins: [CreativeTraits, CreativeMixins, CreativesMixins, SDKMixin],

  components: {
    SearchSelect,
    CreativeRow,
    CreativeSize,
    Button,
    NotificationBox,
  },

  props: {
    creative: {
      type: Object,
    },

    can_reimport_social: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    size_row_label() {
      if (this.isExpandable()) return 'First state size (px):';
      return 'Size (px):';
    },

    has_social_widget() {
      if (this.creative.objects == null) return false;

      const key = Object.keys(this.creative.objects).find(_key => _key.includes('social_widget'));
      const social_widget = this.creative.objects[key];

      return social_widget?.settings?.page_id != null;
    },

    active_type() {
      if (this.isSticky()) return TYPE.STICKY;
      if (this.isCTVVast()) return TYPE.CTV_VAST;

      return this.creative.type;
    },

    type_tooltip_title() {
      return this.type_options.find(type => type.value === this.active_type)?.label || '';
    },

    type_options() {
      // default options
      let type_options = [
        {
          value: -1,
          label: 'Select type',
        },
        {
          value: TYPE.INFEED,
          label: 'Standard Infeed',
          description: 'It appears inside of content feeds.',
          icons: ['nexd-icon-16-mobile', 'nexd-icon-16-desktop'],
        },
        {
          value: TYPE.INTERSTITIAL,
          label: 'Interstitial',
          description: 'Fullscreen ads that overlay the content.',
          icons: ['nexd-icon-16-mobile'],
        },
        {
          value: TYPE.INTERSCROLLER,
          label: 'Interscroller',
          description: 'Scroll-in ad, where creative is fullscreen size, but visible though a viewport (aka understitial or underlay).',
          icons: ['nexd-icon-16-mobile'],
        },
        {
          value: TYPE.EXPANDABLE_INFEED,
          label: 'Expandable',
          description: 'Two-state ad that transition from the first state to the second state.',
          icons: ['nexd-icon-16-mobile'],
        },
        {
          value: TYPE.DYN_END_INFEED,
          label: 'Dynamic endcard',
          description: 'Two-state ad that automatically transition from the first state to the second state.',
          icons: ['nexd-icon-16-mobile'],
        },
        {
          value: TYPE.LIGHTBOX_INFEED,
          label: 'Lightbox',
          description: 'Two-state ad that show the second state with a popup.',
          icons: ['nexd-icon-16-mobile', 'nexd-icon-16-desktop'],
        },
        {
          value: TYPE.VAST,
          label: 'VAST Video',
          description: 'In-stream video ad type for running ads on video-sharing platforms.',
          icons: ['nexd-icon-16-video'],
        },
        {
          value: TYPE.CTV_VAST,
          label: 'CTV VAST Video',
          description: 'In-stream video ad type for video-sharing platforms suitable for UHD (4K).',
          icons: ['nexd-icon-16-video'],
        },
        {
          value: TYPE.VPAID,
          label: 'VPAID Video',
          description: 'Is available on all desktop layouts with video.',
          icons: ['nexd-icon-16-video'],
        },
      ];

      if (!this.is_sdk_user) {
        type_options.push({
          value: TYPE.STICKY,
          label: 'Sticky',
          description: 'A placement fit for seeing the ad stuck to the bottom of the screen.',
          icons: ['nexd-icon-16-mobile', 'nexd-icon-16-desktop'],
        });
      }

      // social options
      if (this.has_social_widget) {
        return [type_options[0], type_options.find(type => type.value === TYPE.INFEED), type_options.find(type => type.value === TYPE.INTERSTITIAL), type_options.find(type => type.value === TYPE.INTERSCROLLER)];
      }

      // html5 options
      if (this.isHTML5()) {
        return [
          type_options[0], // select type
          {
            ...type_options.find(type => type.value === TYPE.INFEED),
            value: TYPE.HTML5_INFEED,
          },
          {
            ...type_options.find(type => type.value === TYPE.INTERSTITIAL),
            value: TYPE.HTML5_INTERSTITIAL,
          },
          {
            ...type_options.find(type => type.value === TYPE.INTERSCROLLER),
            value: TYPE.HTML5_INTERSCROLLER,
          },
          {
            ...type_options.find(type => type.value === TYPE.VPAID),
            value: TYPE.HTML5_VPAID,
          },
        ];
      }

      // if fullscreen then change expandable and dynamic endcard type
      if (this.isSomeSortOfFullscreen()) {
        type_options.find(type => type.value === TYPE.EXPANDABLE_INFEED).value = TYPE.EXPANDABLE_INTERSTITIAL;
        type_options.find(type => type.value === TYPE.DYN_END_INFEED).value = TYPE.DYN_END_INTERSTITIAL;
      }

      type_options.splice(7, 0, {
        value: TYPE.RESPONSIVE_INFEED,
        label: 'Responsive',
        description: 'Multiple sizes of the same creative.',
        icons: ['nexd-icon-16-mobile', 'nexd-icon-16-desktop'],
      });

      // skin option
      if (!this.is_sdk_user && (this.show_skin_type_options || this.isSkin())) {
        type_options.push({
          value: TYPE.SKIN,
          label: 'Skin ad',
          description: 'Experimental skin ad that will change the overall looks of your page.',
          icons: ['nexd-icon-16-desktop'],
        });
      }

      return type_options;
    },

    type_tooltips() {
      const interscroller_txt =
        'Interscroller ads are scroll-in ads, where creatives are fullscreen size, but visible through a viewport by scrolling through publisher content. ' +
        'The viewport / placement size is the visible area purchased on the website where the ad will be published. The size of the creative is set to 640 x 1280px that covers ' +
        'full screen on high aspect ratio mobile devices. When designing, bear in mind that most popular phones have a safe area of 854px.';

      return {
        [TYPE.INFEED]: {
          tooltip: 'Standard infeed ads are the most common ad type that appears inside of content feeds. They are part of the page flow and scroll along just like the rest of the content.',
          link: 'https://www.nexd.com/ad-types/#mobile-infeed',
        },
        [TYPE.INTERSTITIAL]: {
          tooltip: 'Interstitial ads are fullscreen ads that overlay the content.',
          link: 'https://www.nexd.com/ad-types/#mobile-interstitial',
        },
        [TYPE.INTERSCROLLER]: {
          tooltip: interscroller_txt,
          link: 'https://www.nexd.com/ad-types/#mobile-interscroller',
        },
        [TYPE.VAST]: {
          tooltip: 'VAST Video is VAST standard-based ads for video-sharing platform in-stream placements where you can use our video layout.',
          link: 'https://www.nexd.com/ad-types/#instream-video-ad',
        },
        [TYPE.VPAID]: {
          tooltip: 'VPAID Video is VPAID (SIMID) standard-based ads for video-sharing platform in-stream placements where you can use all our layouts.',
          link: 'https://www.nexd.com/ad-types/#instream-video-ad',
        },
        [TYPE.EXPANDABLE_INFEED]: {
          tooltip: `
            Expandable ads are two-state ads that transition from the first state to the second state.
            As the market is transitioning towards more High Aspect Ratio screens, Nexd is introducing a new option to cover all screens with creative area.
            This size is fully seen only on InApp placements.
          `,
          link: 'https://www.nexd.com/ad-types/#mobile-expandable',
        },
        [TYPE.EXPANDABLE_INTERSTITIAL]: {
          tooltip: `
            Expandable ads are two-state ads that transition from the first state to the second state.
            As the market is transitioning towards more High Aspect Ratio screens, Nexd is introducing a new option to cover all screens with creative area.
            This size is fully seen only on InApp placements.
          `,
          link: 'https://www.nexd.com/ad-types/#mobile-expandable',
        },
        [TYPE.DYN_END_INFEED]: {
          tooltip: 'Dynamic endcard ads are two-state ads that start with a video and then show the second state with any mobile layout.',
          link: 'https://support.nexd.com/en/articles/3524425-choosing-between-different-creative-types#h_a9914376dc',
        },
        [TYPE.DYN_END_INTERSTITIAL]: {
          tooltip: 'Dynamic endcard ads are two-state ads that start with a video and then show the second state with any mobile layout.',
          link: 'https://support.nexd.com/en/articles/3524425-choosing-between-different-creative-types#h_a9914376dc',
        },
        [TYPE.LIGHTBOX_INFEED]: {
          tooltip: 'Lightbox is a two-state ad which first state can be any infeed layout and second state is imported HTML5 which opens as a popup.',
          link: 'https://www.nexd.com/ad-types/#lightbox-ad',
        },
        [TYPE.LIGHTBOX_INTERSTITIAL]: {
          tooltip: 'Lightbox is a two-state ad which first state can be any infeed layout and second state is imported HTML5 which opens as a popup.',
          link: 'https://www.nexd.com/ad-types/#lightbox-ad',
        },
        [TYPE.RESPONSIVE_INFEED]: {
          tooltip: 'Responsive is a creative that can be used in different infeed placements. It takes one set of assets and automatically creates multiple ads in different desktop and mobile sizes.',
          link: 'https://www.nexd.com/ad-types/#responsive',
        },
        [TYPE.HTML5_INFEED]: {
          tooltip: 'Standard infeed ads are the most common ad type that appears inside of content feeds. They are part of the page flow and scroll along just like the rest of the content.',
          link: 'https://www.nexd.com/ad-types/#mobile-infeed',
        },
        [TYPE.HTML5_INTERSTITIAL]: {
          tooltip: 'Interstitial ads are fullscreen ads that overlay the content.',
          link: 'https://www.nexd.com/ad-types/#mobile-interstitial',
        },
        [TYPE.HTML5_INTERSCROLLER]: {
          tooltip: interscroller_txt,
          link: 'https://www.nexd.com/ad-types/#mobile-interscroller',
        },
        [TYPE.HTML5_VPAID]: {
          tooltip: 'VPAID Video is VPAID (SIMID) standard-based ads for video-sharing platform in-stream placements where you can use all our layouts.',
          link: 'https://www.nexd.com/ad-types/#instream-video-ad',
        },
        [TYPE.STICKY]: {
          tooltip: 'Sticky placements are most common on mobile pages/apps that stick to the screen and are usually less intrusive in their size (e.g. 320x50 for mobile and 1000x50 for desktop).',
          link: 'https://support.nexd.com/en/articles/3524425-choosing-between-different-creative-types#h_a9914376dc',
        },
        [TYPE.CTV_VAST]: {
          tooltip: `
            CTV VAST video is the upgraded VAST type for video-sharing platforms that run in wide screens such as TV.
            The default resolution is UHD(4K) making the CTV VAST type the right choice for the ad to be delivered in high quality.
          `,
          link: 'https://www.nexd.com/ad-types/#instream-video-ad',
        },
      };
    },

    expandable_second_state_disabled() {
      return this.isExpandable() && this.isSomeSortOfFullscreen();
    },

    expandable_second_state_tooltip() {
      if (this.expandable_second_state_disabled) {
        return {
          value: 'If creative first state is fullscreen then second state must be also fullscreen.',
        };
      }
      return {};
    },

    expandable_first_state_disabled() {
      return this.isExpandable() && !this.isSomeSortOfFullscreen(this.creative?.expanded?.type);
    },

    expandable_first_state_tooltip() {
      if (this.expandable_first_state_disabled) {
        return {
          value: 'If creative second state is infeed then first state must be also infeed.',
        };
      }
      return {};
    },
  },

  data() {
    return {
      admin_toggle: false,

      switching_type: false,
      disabled: false,
      warning: {},
    };
  },

  created() {
    this.$user.subscribe(_ => {
      this.admin_toggle = this.$user.adminMode();
    }, this);

    if (this.isBinded()) {
      if (this.is_new) {
        this.mockExpanded();
      }
    }
  },

  methods: {
    tagPlacementHandler(type, creative) {
      let type_placement = null;

      switch (type) {
        case 7:
          type_placement = 'sitehat';
          break;
        case TYPE.STICKY:
          type_placement = 'sticky';
          break;
      }

      const current_placement = creative.settings?.tag?.placement ?? null;

      if (type_placement !== current_placement) {
        if (!creative.settings) {
          this.$set(creative, 'settings', {});
        }
        if (!creative.settings.tag) {
          this.$set(creative.settings, 'tag', {});
        }
        this.$set(creative.settings.tag, 'placement', type_placement);
      }

      if (type === TYPE.CTV_VAST) {
        if (!creative.settings) {
          this.$set(creative, 'settings', {});
        }

        this.$set(creative.settings, 'ctv_format', true);
      } else if (creative.settings?.ctv_format) {
        this.$delete(creative.settings, 'ctv_format');
      }
    },

    async confirmExpandableToStandardSwitch(creative, type_match = true) {
      const result = await this.$confirm(
        'Are you sure you want to continue?',
        `Changing type from expandable to any other type, will remove expanded layout from the creative. ${type_match ? '' : NOT_COMPATIBLE_LAYOUT_MESSAGE}`,
      );
      if (result) this.$set(creative, 'expanded', null);
      return result;
    },

    async confirmTemplateRemoval(creative) {
      // if no template chosen, return true instantly
      if (creative.template_id == null && creative.layout_id == null) return true;

      const result = await this.$confirm('Are you sure you want to change the placement type?', NOT_COMPATIBLE_LAYOUT_MESSAGE);

      // remove cached data before backend responds
      if (result) {
        this.removeTemplate(creative);

        // resize assets lib, because content collapses
        this.resizeAssetsLibrary();
      }

      return result;
    },

    removeTemplate(creative) {
      this.$set(creative, 'template_id', null);
      this.$set(creative, 'template', null);
      this.$set(creative, 'layout_id', null);
      this.$set(creative, 'layout', null);
      this.$set(creative, 'preview_url', null);
    },

    async confirmTypeChange(creative, current_type, new_type) {
      if (current_type === new_type) return new_type;

      const current_layout_type = creative?.template?.type ?? creative?.layout?.type ?? null;
      if (current_layout_type == null) return new_type;

      // expandable can be converted to infeed or fullscreen
      if (this.isBinded(current_type) && !this.isBinded(new_type) && (this.isInfeed(new_type) || this.isFullscreen(new_type))) {
        // if 2nd state does not exists, we dont need to show the confirmation
        const expanded_state_has_template = this.creative.expanded?.template_id != null;
        if (!expanded_state_has_template) return new_type;

        const type_matches = (this.isInfeed(new_type) && this.isInfeed(current_layout_type)) || (this.isFullscreen(new_type) && this.isFullscreen(current_layout_type));
        if (await this.confirmExpandableToStandardSwitch(creative, type_matches)) return new_type;
        return current_type;
      }

      if (this.isInfeed(new_type) && this.isVast()) {
        if (await this.$confirm('Convert to standard ad?', 'Do you wish to convert this VAST creative into standard ad? We will keep all assets and tracking settings for current creative.')) {
          return new_type;
        }
        return current_type;
      }

      if (this.isQuantum(current_type)) {
        if (await this.confirmTemplateRemoval(creative)) return new_type;
        return current_type;
      }

      let type_check = new_type; // for expandable & dyn. endcard type check to use 1,0 not 10,11,20,21
      if (this.isBinded(current_type)) {
        if (this.isSomeSortOfInfeed(new_type)) {
          type_check = 0;
        } else if (this.isSomeSortOfFullscreen(new_type)) {
          type_check = 1;
        }
      }

      if (
        this.isSkin(new_type) ||
        this.isVast(new_type) ||
        this.isQuantum(new_type) ||
        (this.isBinded(new_type) && this.isInterscroller(current_layout_type)) ||
        (this.isInterscroller(new_type) && this.isInfeed(current_layout_type)) ||
        (this.isVpaid(new_type) && (this.isFullscreen(current_layout_type) || this.isInterscroller(current_layout_type))) ||
        (this.isInfeed(type_check) && (this.isFullscreen(current_layout_type) || this.isInterscroller(current_layout_type) || this.isSkin(current_layout_type))) ||
        (this.isFullscreen(type_check) && (this.isInfeed(current_layout_type) || this.isInterscroller(current_layout_type)))
      ) {
        if (await this.confirmTemplateRemoval(creative)) return new_type;
        return current_type;
      }

      return new_type;
    },

    async changeLayout(creative) {
      if (creative.template_id != null) {
        this.$set(creative, 'template', await TemplatesService.get(creative.template_id));
        return true;
      } else if (creative.layout_id != null) {
        this.$set(creative, 'layout', await TemplatesService.get(creative.layout_id));
        return true;
      }
      return false;
    },

    async typeChangeHandler(new_type, creative = null) {
      // if expandable and first state is fullscreen then disable second state change
      if (creative != null && this.expandable_second_state_disabled) return;
      if (creative == null && this.creative == null && this.expandable_first_state_disabled) return;

      if (creative == null) {
        creative = this.creative;
      }

      let switch_to_sticky = false;
      let switch_from_sticky = this.isSticky(creative);

      let switch_to_ctv_vast = false;
      let switch_from_ctv_vast = this.isCTVVast(creative);

      // type 60 is an alias for infeed, with sticky setting
      if (new_type === TYPE.STICKY) {
        new_type = TYPE.INFEED;
        // will make type change request with type 60
        switch_to_sticky = true;
      }

      // type 61 is an alias for ctv vast, with ctv_format setting
      if (new_type === TYPE.CTV_VAST) {
        new_type = TYPE.VAST;
        // will make type change request with type 61
        switch_to_ctv_vast = true;
      }

      const current_type = creative.type;
      if (current_type !== new_type) {
        new_type = await this.confirmTypeChange(creative, current_type, new_type);
      }

      // if cancel then stop process
      if (new_type === current_type && !switch_to_sticky && !switch_from_sticky && !switch_to_ctv_vast && !switch_from_ctv_vast) {
        return;
      }

      if (new_type !== current_type && this.hasLORT()) {
        this.$emit('loadTemplate', true);
      }

      this.$emit('typeChange');

      const getCorrectType = () => {
        switch (true) {
          case switch_to_sticky:
            return TYPE.STICKY;
          case switch_to_ctv_vast:
            return TYPE.CTV_VAST;
          default:
            return new_type;
        }
      };

      if (!this.is_new) {
        this.switching_type = true;

        // if type was changed to sticky, send alias 60 to the backend instead of sending 0 (infeed)
        return this.$http
          .put(`creative/${creative.creative_id}/type`, {
            type: getCorrectType(),
          })
          .then(async response => {
            for (const key in response) {
              this.$set(creative, key, response[key]);
            }

            // so some things when layout actually changed
            if (await this.changeLayout(creative)) {
              this.reloadAssetsLibrary();
              this.resizeAssetsLibrary();
              this.validateLayoutType();
            }

            CreativeSizeService.validate(this.creative);

            this.refreshCreativePacksize();

            if (this.isDynamicEndcard() && creative.expanded != null) {
              this.$set(creative.expanded, 'template', null);
              this.$set(creative.expanded, 'layout', null);
              this.$set(creative.expanded, 'template_id', null);
              this.$set(creative.expanded, 'layout_id', null);
            }
          })
          .catch(_ => {
            /** error show with notification*/
          })
          .finally(() => {
            this.$emit('loadTemplate', false);
            this.switching_type = false;
          });
      } else {
        CreativeSizeService.reset();
      }

      // will handle adding correct settings.tag.palcement value
      this.tagPlacementHandler(getCorrectType(), creative);

      this.$set(creative, 'type', new_type);

      if (this.isSkin(new_type)) {
        this.$set(creative, 'width', 1920);
        this.$set(creative, 'height', 1080);
        this.$set(creative, 'device', 1);
      } else if (this.isSomeSortOfFullscreen(new_type)) {
        this.$set(creative, 'device', 1);
        this.$set(creative, 'width', SIZE.INTERSTITIAL.WIDTH);
        this.$set(creative, 'height', SIZE.INTERSTITIAL.HEIGHT_HIGH_ASPECT);
      } else if (this.isVast(new_type)) {
        // device important before create endpoint is being refactored
        this.$set(creative, 'device', 2);

        if (switch_to_ctv_vast) {
          this.$set(creative, 'width', 3840);
          this.$set(creative, 'height', 2160);
        } else {
          this.$set(creative, 'width', 1280);
          this.$set(creative, 'height', 720);
        }

        // auto create vast
        this.$emit('createNewCreative');
      } else if (this.isVpaid(new_type)) {
        this.$set(creative, 'device', 2);
      } else if (this.isQuantum(new_type)) {
        this.$set(creative, 'width', 640);
        this.$set(creative, 'height', 640);
      }

      if (this.creative.expanded == null && this.isBinded()) {
        this.mockExpanded();
      }

      if (creative.expanded != null && !this.isBinded()) {
        this.$set(creative, 'expanded', null);
      }

      if (this.isExpandable() && this.creative?.type === TYPE.EXPANDABLE_INTERSTITIAL && this.creative?.expanded?.type === TYPE.INFEED) {
        this.$set(creative.expanded, 'type', TYPE.INTERSTITIAL);
      } else if (this.isDynamicEndcard()) {
        if (this.isSomeSortOfInfeed()) {
          this.$set(this.creative, 'template_id', TEMPLATE.DYN_END_INFEED_DEFAULT);
        } else if (this.isSomeSortOfFullscreen()) {
          this.$set(this.creative, 'template_id', TEMPLATE.DYN_END_INTERSTITIAL_DEFAULT);
        }
        this.$emit('createNewCreative');
      } else if (this.isLightbox()) {
        if (this.creative?.type === TYPE.LIGHTBOX_INTERSTITIAL) {
          this.$set(creative, 'type', TYPE.LIGHTBOX_INFEED);
        }
        if (this.creative?.expanded?.type === TYPE.INFEED) {
          this.$set(creative.expanded, 'type', TYPE.HTML5_INTERSTITIAL);
        }
      }

      this.changeLayout(creative);
    },

    resizeAssetsLibrary() {
      if (this.$store.get('active_assets_library') != null) {
        this.$store.get('active_assets_library').setScrollHeight();
      }
    },

    reloadAssetsLibrary() {
      if (this.creative.assets_library_id != null) {
        AssetsLibraryService.load();
      }
    },

    refreshCreativePacksize() {
      if (this.is_new) return;

      this.$http.get(`creatives/${this.creative.creative_id}/packsize`).then(packsize => {
        this.$set(this.creative, 'packsize', packsize);
      });
    },

    validateLayoutType() {
      if (this.hasLORT()) {
        let type_check = this.creative.type;

        if ((this.isInterscroller() && !this.isTemplateInterscroller()) || this.isSomeSortOfFullscreen()) {
          type_check = 1;
        }

        if ((this.isSomeSortOfInfeed() && !this.isInterscroller() && !this.isQuantum()) || this.isVideo()) {
          type_check = 0;
        }

        if ((this.hasTemplate() && this.creative.template.type !== type_check) || (this.hasLayout() && this.creative.layout.type !== type_check)) {
          this.$alert('This layout is not fully compatible with current placement type.');
        }
      }
    },

    mockExpanded() {
      if (this.creative.expanded != null) return;

      this.$set(this.creative, 'expanded', {
        width: SIZE.INTERSTITIAL.WIDTH,
        height: SIZE.INTERSTITIAL.HEIGHT_HIGH_ASPECT,
        type: 1,
        device: 0,
      });
    },

    sizeChangeHandler(expanded = false) {
      if (!this.is_new) {
        const creative = expanded ? this.creative.expanded : this.creative;
        return this.$http
          .put(
            `v2/creative/${creative.creative_id}`,
            {
              width: creative.width,
              height: creative.height,
            },
            { notification: false },
          )
          .catch(() => {
            /** supress errors */
          });
      }

      if (this.creative.template_id != null || this.creative.layout_id != null || this.isDynamicEndcard()) {
        return this.$emit('createNewCreative');
      }
    },
  },
};
</script>
